<script setup>
    import { ref, watch, onMounted } from 'vue'
    import Fraction from '@/fraction.js';

    const props = defineProps(['step', 'ruler']);
    const emit = defineEmits(['fraction-changed']);

    let options = [ {text: '--', value: 0} ];
    Fraction.values.forEach((fraction) => {
        options.push(fraction);
    });

    const elementId = `${props.step.id}-${props.ruler.id}-fraction`;
    const currentValue = ref(Fraction.split(props.ruler.current.value).decimal);

    onMounted(() => {        
        // watch for changes
        watch(currentValue, () => {
            emit('fraction-changed', props.step, props.ruler, currentValue.value);
        });

        watch(props.ruler.current, () => {
            const decimal = Fraction.split(props.ruler.current.value).decimal;
            if (currentValue.value != decimal) {
                currentValue.value = decimal;
            }
        });
    });
</script>

<template>
    <div class="select measure__select">
        <BFormSelect :id="elementId" v-model="currentValue" :options="options" class="select__input select__input--fraction w-select"/>
        <label class="select__label label--left">In</label>
    </div>
</template>