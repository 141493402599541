<script setup>
    import { watch, onMounted } from 'vue'
    import noUiSlider from 'nouislider';
    import 'nouislider/dist/nouislider.css';
    import Fraction from '@/fraction.js';

    const props = defineProps(['step', 'ruler']);    
    const emit = defineEmits(['ruler-changed']);
    const elementId = `${props.step.id}-${props.ruler.id}-slider`;

    onMounted(() => {
        function genPipPositions(pipScale){
            const values = [];
            for (let i = 0; i < 16; i++){
                values.push(pipScale * i);
            }
            return values;
        }

        let pips = {
            mode: 'positions',
            values: genPipPositions(6.667),
            density: 1,
            stepped: true,
        };

        var start = props.ruler.current.value;
        if (props.ruler.precision == 1) {
            // if the ruler doesn't take decimals, then it automatically rounds off the decimal portion
            // however, it rounds according to normal rules and instead we need it to just drop the decimals
            // TODO: long-term we may want to be smarter about this so decimal precisions can be handled too (if precision is .25 and the current.value is .375)
            start = Math.floor(start);
        }

        const sliderElement = document.getElementById(elementId);
        if (sliderElement) {
            const slider = noUiSlider.create(sliderElement, {
                start: [start],
                step: props.ruler.precision,
                range: { min: props.ruler.min.value, max: props.ruler.max.value },
                behaviour: 'tap-drag',
                tooltips: true,
                format: Fraction.format,
                pips: pips
            });

            // bind the slider to send changes
              slider.on('update', function () { 
                emit('ruler-changed', props.step, props.ruler, Fraction.format.from(this.get()));
            });

            // bind the slider to receive changes
            watch(props.ruler.current, () => {
                // see if the precision is a whole number or not, so we know if we may need to grab just the whole number portion of the value
                let newValue = props.ruler.current.value;
                if (props.ruler.precision % 1 == 0) {
                    newValue = Fraction.split(props.ruler.current.value).whole;
                }

                slider.setHandle(0, newValue, false);
            }); 
        }
        else {
            console.error('An unexpected error occurred when attempting to initialize the noUISlider!');
        }
    });
</script>

<template>
    <div class="ruler ruler--left">
        <div v-if="props.ruler.label == 'A'" class="ruler--label">
            <div>
                {{ props.ruler.min.value }} in
            </div>
            <div>
                {{ props.ruler.max.value }} in
            </div>
        </div>
        <!-- <div>{{ props.ruler }}</div> -->
        <div :id="elementId"></div>
    </div>
</template>